export const defaultSelectOption = { id: '', label: '- unset -' }
export const sizeOptionsLabel = { id: '', label: 'dimension de benne ' }

export const paidStatusOptions = [
  defaultSelectOption,
  {
    label: 'Paye',
    id: '1',
  },
  {
    label: 'Avec prix (impayé)',
    id: '2',
  },
  {
    label: 'Avec prix',
    id: '3',
  },
  {
    label: 'Pas de prix',
    id: '4',
  },
  {
    label: 'Avec TTC',
    id: '5',
  },
  {
    label: 'Pas de TTC (cash)',
    id: '6',
  },
  {
    label: 'Travail suppl.',
    id: '7',
  },
]

export const debtsStatusOptions = [
  defaultSelectOption,
  {
    id: '1',
    label: 'Paye',
  },
  {
    id: '2',
    label: 'Impayé',
  },
]

export const sizeOptions = [
  {
    id: '1',
    label: '8m³',
  },
  {
    id: '2',
    label: '10m³',
  },
  {
    id: '3',
    label: '12m³',
  },
  {
    id: '4',
    label: '15m³',
  },
  {
    id: '5',
    label: '30m³',
  },
]

export const statusOptionsList = [
  defaultSelectOption,
  {
    id: '3',
    label: 'Pansante',
  },
  {
    id: '2',
    label: 'Dépôt',
  },
  {
    id: '1',
    label: 'Echangé',
  },
  {
    id: '0',
    label: 'Récupéré',
  },
  {
    id: '4',
    label: 'P.A.V',
  },
]

export const trashTypes = [
  {
    id: '1',
    label: 'Gravats',
  },
  {
    id: '2',
    label: 'Dechets',
  },
  {
    id: '5',
    label: 'Bois',
  },
  {
    id: '6',
    label: 'Vegetaux',
  },
  {
    id: '7',
    label: 'Mousse',
  },
]

export const captureServiceTypes = {
  1: {
    id: '1',
    label: 'Livrasion de benne',
  },
  2: {
    id: '2',
    label: 'Pansante benne',
  },
  3: {
    id: '3',
    label: 'Dépôt benne',
  },
  4: {
    id: '4',
    label: 'Echangé benne',
  },
  5: {
    id: '5',
    label: 'Benne surcharge',
  },
  6: {
    id: '6',
    label: 'Service offert décalage',
  },
  7: {
    id: '7',
    label: "Détérioration de l'équipement",
  },
}

export const sizeOptionsList = [defaultSelectOption, ...sizeOptions]
export const trashTypeOptionsList = [defaultSelectOption, ...trashTypes]

export const orderCountLabels = [
  {
    id: 1,
    label: '1',
  },
  {
    id: 2,
    label: '2',
  },
  {
    id: 3,
    label: '3',
  },
  {
    id: 4,
    label: '4',
  },
  {
    id: 5,
    label: '5',
  },
]

export const depoCoords = {
  lat: 48.9980835,
  lng: 2.4770252,
}
